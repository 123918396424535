import * as React from 'react';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';

export default function TailContainer() {
  return (
    <AppBar position="static" className="header-background">
      <Typography className="font-golos-white">
        © Версия УЗиТ МТ 2.0 (РИР) от 24.08.2023
      </Typography>
    </AppBar>
  );
}
