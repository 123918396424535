import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
  CardMedia,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Route';
import ChartReportPageSmall from './../../reports/ChartReportPageSmall';
import PublishIcon from '@mui/icons-material/Publish';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PendingIcon from '@mui/icons-material/Pending';
import useMediaQuery from '@mui/material/useMediaQuery';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';

import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import { useTheme, createTheme } from '@mui/material/styles';

import MapIcon from '@material-ui/icons/Map';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import CommuteIcon from '@material-ui/icons/Commute';
import PersonIcon from '@material-ui/icons/Person';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import { useAttributePreference } from '../util/preferences';
import { useCatch, useCatchCallback } from '../../reactHelper';
import { devicesActions } from '../../store';
import usePositionAttributes from '../attributes/usePositionAttributes';
import { useDeviceReadonly } from '../util/permissions';
import PositionValue from './PositionValue';
import RemoveDialog from './RemoveDialog';
import { useTranslation } from './LocalizationProvider';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      //padding="1px"
      //style={{height:'400px'}}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}
        style={{padding:'1px', 
        //height:'400px'
      }}

        >
          <Typography
          style={{height:'300px'}}
          >{children}</Typography>
        </Box>
      )}
    </div>
  );
};

function a11yProps(index) {
  // console.log(index);
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  icons: {
    color: theme.palette.colors.iconsColor,
    // display: 'flex',
    // fontSize : 'medium'
  },
  labelParent: {
    // flexDirection: 'column',
    // justifyContent: 'center',
    // height: '100%',

    display: 'flex',
    alignItems: 'center',
  },
  labelButton: {
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',

    display: 'flex',
    alignItems: 'center',
    textTransform: 'unset',
    display: 'inline-block',
    minHeight: 'unset',
    fontSize: '14px',
    // padding: '0px',
    // bottom:'5px',
    // height:'20px',
    // position: 'fixed',
    /*
    width: '70px';
    height: 70px;
    padding: 15px;
    border: 1px solid blue;
    background-color: yellow;
    */
  },

  box: {
    left: '360px',
    right: '0px',
    bottom: '21px',
    pointerEvents: 'auto',
    width: '100%-360px',
    position: 'fixed',
    height: '400px',
    backgroundColor: 'white',
  },
  tabpanel: {
    padding: '1px',
  },
  card: {
    width: '100%',
    //height: '100%',
    //padding: '1px',
    /*
    left:'360px',
    right:'0px',
    bottom:'21px',
    pointerEvents: 'auto',
    width: '100%-360px',
    position: 'fixed',
    height:'400px'
    */
  },
  media: {
    height: theme.dimensions.popupImageHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  mediaButton: {
    color: theme.palette.colors.white,
    mixBlendMode: 'difference',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
    //padding: '1px'
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height:'268px'
    //height:'100vh-50px'
    //padding: '1px'
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: 'none',
  },
  actions: {
    justifyContent: 'space-between',
  },
  /*
  root: ({ desktopPadding }) => ({
    pointerEvents: 'none',
    position: 'fixed',
    zIndex: 5,
    left: '50%',
    //width:'100%',
    [theme.breakpoints.up('md')]: {
      left: `calc(50% + ${desktopPadding} / 2)`,
      bottom: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      left: '50%',
      bottom: `calc(${theme.spacing(3)} + ${theme.dimensions.bottomBarHeight}px)`,
    },
    transform: 'translateX(-50%)',
  }),
  */
 
  root: ({ desktopPadding }) => ({

    zIndex: 5,

  }),
  
}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">{content}</Typography>
      </TableCell>
    </TableRow>
  );
};

const StatusCard = ({ deviceId, position, onClose, disableActions, desktopPadding = 0 }) => {
  const classes = useStyles({ desktopPadding });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const deviceReadonly = useDeviceReadonly();

  const device = useSelector((state) => state.devices.items[deviceId]);

  const deviceImage = device?.attributes?.deviceImage;

  const positionAttributes = usePositionAttributes(t);
  const positionItems = useAttributePreference('positionItems', 'speed,address,totalDistance,course');

  const [anchorEl, setAnchorEl] = useState(null);

  const [removing, setRemoving] = useState(false);

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  const handleGeofence = useCatchCallback(async () => {
    const newItem = {
      name: '',
      area: `CIRCLE (${position.latitude} ${position.longitude}, 50)`,
    };
    const response = await fetch('/api/geofences', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newItem),
    });
    if (response.ok) {
      const item = await response.json();
      const permissionResponse = await fetch('/api/permissions', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ deviceId: position.deviceId, geofenceId: item.id }),
      });
      if (!permissionResponse.ok) {
        throw Error(await permissionResponse.text());
      }
      navigate(`/settings/geofence/${item.id}`);
    } else {
      throw Error(await response.text());
    }
  }, [navigate, position]);

  const [value, setValue] = React.useState(0);

  const [menu2, setmenu2] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    // console.log(newValue);
    // setmenu2(newValue);
  };
  const handleChange0 = (event, newValue) => { setValue(0); };
  const handleChange1 = (event, newValue) => { setValue(1); };
  const handleChange2 = (event, newValue) => { setValue(2); };
  const handleChange3 = (event, newValue) => { setValue(3); };

  return (
    <>
      <div className={classes.root}>
        {device && (
        <Box className={classes.box}>

          <div className="container2">
            <div className="middle-column">
              <Tabs
                className="top-menu2"
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Box
                  sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, marginLeft: '15px', marginRight: '15px' }}
                  style={{ float: 'left' }}
                  onClick={handleChange0}
                >
                  <div className="top-menu2">
                    <DirectionsCarIcon className="top-menu-icon2" />
                    <Button>
                      {' '}
                      <div className="top-menu-text2">Информация о ТС</div>
                      {' '}
                    </Button>
                  </div>
                </Box>
                {/*
                <Box
                  sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, marginLeft: '15px', marginRight: '15px' }}
                  style={{ float: 'left' }}
                  onClick={handleChange1}
                >
                  <div className="top-menu2">
                    <AssignmentIcon className="top-menu-icon2" />
                    <Button>
                      {' '}
                      <div className="top-menu-text2">Статистика</div>
                      {' '}
                    </Button>
                  </div>
                </Box>
                */
                }
                <Box
                  sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, marginLeft: '15px', marginRight: '15px' }}
                  style={{ float: 'left' }}
                  onClick={handleChange1}
                >
                  <div className="top-menu2">
                    <SsidChartIcon className="top-menu-icon2" />
                    <Button>
                      {' '}
                      <div className="top-menu-text2">Датчики</div>
                      {' '}
                    </Button>
                  </div>
                </Box>

              </Tabs>
            </div>
            <div className="right-column2">
              <Box
                sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, marginRight: '50px' }}
                style={{ float: 'right' }}
              >
                <div className="top-menu2" style={{ textAlign: 'right' }}>
                  <IconButton
                    size="small"
                    onClick={onClose}
                    onTouchStart={onClose}
                    className="top-menu-icon2"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </Box>
            </div>
          </div>

          <TabPanel 
            value={value} 
            index={0}
            //className={theme}
            //sx={{ padding: '1px'}}
            //style={{ padding: '1px' }}
            >

            <Card
              // elevation={3}
              className={classes.card}
              //sx={{ padding: '2px'}}
              //style={{ padding: '2px' }}
            >
              {(deviceImage == 2) ? (
                <CardMedia
                  className={classes.media}
                  image={`/api/media/${device.uniqueId}/${deviceImage}`}
                >
                  <IconButton
                    size="small"
                    onClick={onClose}
                    onTouchStart={onClose}
                  >
                    <CloseIcon fontSize="small" className={classes.mediaButton} />
                  </IconButton>
                </CardMedia>
              ) : (
                <div className={classes.header}>
                  <Typography variant="body2" color="textSecondary">
                    {device.name}
                  </Typography>

                </div>
              )}
              {position && (
              <CardContent className={classes.content}
              >
                <Table size="small" classes={{ root: classes.table }}>
                  <TableBody>
                    {positionItems.split(',').filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                      <StatusRow
                        key={key}
                        name={positionAttributes.hasOwnProperty(key) ? positionAttributes[key].name : key}
                        content={(
                          <PositionValue
                    position={position}
                    property={position.hasOwnProperty(key) ? key : null}
                    attribute={position.hasOwnProperty(key) ? null : key}
                  />
                          )}
                      />
                    ))}
                  </TableBody>
                </Table>
              </CardContent>
              )}
              <CardActions classes={{ root: classes.actions }} disableSpacing>
                <IconButton
                  color="secondary"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  disabled={!position}
                >
                  <PendingIcon />
                </IconButton>
                <IconButton
                  onClick={() => navigate('/replay')}
                  disabled={disableActions || !position}
                >
                  <ReplayIcon />
                </IconButton>
                <IconButton
                  onClick={() => navigate(`/settings/command-send/${deviceId}`)}
                  disabled={disableActions}
                >
                  <PublishIcon />
                </IconButton>
                <IconButton
                  onClick={() => navigate(`/settings/device/${deviceId}`)}
                  disabled={disableActions || deviceReadonly}
                >
                  <EditIcon />
                </IconButton>
                <div></div>
              </CardActions>
            </Card>

          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChartReportPageSmall/>
          </TabPanel>
        </Box>
        )}
      </div>
      {position && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => navigate(`/position/${position.id}`)}><Typography color="secondary">{t('sharedShowDetails')}</Typography></MenuItem>
          <MenuItem onClick={handleGeofence}>{t('sharedCreateGeofence')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`}>{t('linkGoogleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`http://maps.apple.com/?ll=${position.latitude},${position.longitude}`}>{t('linkAppleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}&heading=${position.course}`}>{t('linkStreetView')}</MenuItem>
        </Menu>
      )}
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
    </>
  );
};

export default StatusCard;
