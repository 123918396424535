import React from 'react';
import { makeStyles } from '@mui/styles';
//import { ReactComponent as Logo } from '../resources/images/logo159x56.png';
//import Logo from '../resources/images/logo630.png';
//import Logo from '../resources/images/logo200x60.png';
import Logo from '../resources/images/RIR-Logo_color_hor.svg';
//import Logo from '../resources/images/svg.svg';

const useStyles = makeStyles(() => ({
  image: {
    //alignSelf: 'center',
    //maxWidth: '200px',
    maxHeight: '50px',
    width: 'auto',
    height: 'auto',
    "margin-bottom": "-5px",
    "margin-top": "5px",

  },
}));

const LogoImage = ({ color }) => {
  const classes = useStyles();

  //return (<Logo className={classes.image} style={{ color }} />);
  return (<img className={classes.image} style={{ color }} src={Logo}/>);
};

export default LogoImage;
