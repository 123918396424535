import CommuteIcon from '@material-ui/icons/Commute';
import MapIcon from '@material-ui/icons/Map';
import DescriptionIcon from '@material-ui/icons/Description';
import SettingsIcon from '@material-ui/icons/Settings';
import StorageIcon from '@mui/icons-material/Storage';
import PublishIcon from '@mui/icons-material/Publish';
import PeopleIcon from '@mui/icons-material/People';
import TodayIcon from '@mui/icons-material/Today';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ReplayIcon from '@mui/icons-material/Route';
import NotificationsIcon  from '@mui/icons-material/Notifications';
import FolderIcon  from '@mui/icons-material/Folder';
import PersonIcon  from '@mui/icons-material/Person';


var mapTransport=[
   {
      "id":1,
      "url":"/settings/devices",
      "text":"sharedDevices",
      "icon":<CommuteIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },   {
      "id":2,
      "url":"/settings/drivers",
      "text":"sharedDrivers",
      "icon":<PersonIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
   {
      "id":3,
      "url":"/settings/groups",
      "text":"settingsGroups",
      "icon":<FolderIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
];
var mapMonitoring=[
   {
      "id":1,
      "url":"/",
      "text":"mapTitle",
      "icon":<MapIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },   {
      "id":2,
      "url":"/replay",
      "text":"reportReplay",
      "icon":<ReplayIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
   {
      "id":3,
      "url":"/settings/notifications",
      "text":"sharedNotifications",
      "icon":<NotificationsIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
];
var mapReport=[
   {
      "id":1,
      "url":"/reports/summary",
      "text":"reportSummary",
      "icon":<FormatListBulletedIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },   {
      "id":2,
      "url":"/reports/stop",
      "text":"reportStops",
      "icon":<PauseCircleFilledIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
   {
      "id":3,
      "url":"/reports/trip",
      "text":"reportTrips",
      "icon":<PlayCircleFilledIcon  className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
   {
      "id":4,
      "url":"/reports/event",
      "text":"reportEvents",
      "icon":<NotificationsActiveIcon  className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
];
var mapSetting=[
   {
      "id":1,
      "url":"/settings/preferences",
      "text":"sharedPreferences",
      "icon":<SettingsIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },   {
      "id":2,
      "url":"/settings/calendars",
      "text":"sharedCalendars",
      "icon":<TodayIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
   {
      "id":3,
      "url":"/settings/attributes",
      "text":"sharedComputedAttributes",
      "icon":<StorageIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
   {
      "id":4,
      "url":"/settings/server",
      "text":'settingsServer',
      "icon":<StorageIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
   {
      "id":5,
      "url":"/settings/commands",
      "text":"sharedSavedCommands",
      "icon":<PublishIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
   {
      "id":6,
      "url":"/settings/users",
      "text":"settingsUsers",
      "icon":<PeopleIcon className='top-menu-icon' fontSize="small"/>,
      "view":true,
   },
];

var mapMenuTop = 
[ 
   {
      "id":1,
      "url":"/atp/",
      "text":"Транспорт",
      "icon":<CommuteIcon className='top-menu-icon' fontSize="large"/>,
      "view":true,
      "subMenu":mapTransport,
   },
   {
      "id":2,
      "url":"/atp/",
      "text":"Мониторинг",
      "icon":<MapIcon className='top-menu-icon' fontSize="large"/>,
      "view":true,
      "subMenu":mapMonitoring,
   },
   {
      "id":3,
      "url":"/atp/",
      "text":"Отчеты",
      "icon":<DescriptionIcon className='top-menu-icon' fontSize="large"/>,
      "view":true,
      "subMenu":mapReport,
   },
   {
      "id":4,
      "url":"/atp/",
      "text":"Настройка",
      "icon":<SettingsIcon className='top-menu-icon' fontSize="large"/>,
      "view":true,
      "subMenu":mapSetting,
   },
];


export {
  mapMenuTop
};
