import React from 'react';
import { useMediaQuery, Paper,Container,Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import LogoImage from './LogoImage';
import Typography from '@mui/material/Typography';
//import Logo from '../resources/images/logo159x56.png';
import { borders } from '@material-ui/system';

const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //className:'login-background',
    height: '97%',
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // background: theme.palette.primary.main,
    background: theme.palette.colors.logo_background,
    paddingBottom: theme.spacing(5),
    width: theme.dimensions.sidebarWidth,
    //[theme.breakpoints.down('lg')]: {
      //width: theme.dimensions.sidebarWidthTablet,
    //},
    //[theme.breakpoints.down('sm')]: {
      //width: '0px',
    //},
  },
  paper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    //boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    direction:"row",
    top: "25%",
    //backgroundColor:'white',
    //borderRadius:'16px',
    //[theme.breakpoints.up('lg')]: {
      //padding: theme.spacing(0, 25, 0, 0),
    //},
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    backgroundColor:'white',
    width: '100%',
    borderRadius:'16px',
  },
}));

//
//<Box >
//<Box component="main" maxWidth="xs" className='login-container'>
const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  
  return (
    <main className={classes.root}>
      <Grid  className={classes.paper}>
        
        <form className={classes.form} >
          {children}
        </form>
        
      </Grid>

    </main>
  );
};
//
export default LoginLayout;
/*
<div className={classes.sidebar}>
{
  // !useMediaQuery(theme.breakpoints.down('lg')) && <LogoImage color={theme.palette.secondary.contrastText} />
  !useMediaQuery(theme.breakpoints.down('lg')) && 
  <div>
    <LogoImage color={theme.palette.colors.logo_color} />
    <Typography component="h1" variant="h5">
      МОДУЛЬ ТРАНСПОРТА УЗИТ
    </Typography>
  </div>
}
</div>
*/