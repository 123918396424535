import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { makeStyles } from '@mui/styles';

import PersonIcon from '@material-ui/icons/Person';

import { useNavigate } from 'react-router-dom';
import { mapMenuTop } from './header_menu';

import { nativePostMessage } from '../common/components/NativeInterface';
import LogoImage from '../login/LogoImage';
import { useTranslation } from '../common/components/LocalizationProvider';

import {
  useAdministrator, useManager, useRestriction,
} from '../common/util/permissions';
import useFeatures from '../common/util/useFeatures';

const useStyles = makeStyles((theme) => ({
  icons: {
    color: theme.palette.colors.iconsColor,
    display: 'flex',
  },
}));
const pages = ['Products', 'Pricing', 'Blog'];
const HeaderSubMenu = (props) => {
  //console.log(props);
  const t = useTranslation();
  const readonly = useRestriction('readonly');
  const admin = useAdministrator();
  const manager = useManager();
  const features = useFeatures();
  //console.log(admin);
  var b=true;
  if (props.itemMenuSub.text=='settingsGroups') b=(!features.disableGroups)&&(!readonly)?true:false;
  if (props.itemMenuSub.text=='sharedDrivers') b=(!features.disableDrivers)&&(!readonly)?true:false;
  if (props.itemMenuSub.text=='sharedDevices') b=(!readonly)?true:false;
  if (props.itemMenuSub.text=='sharedSavedCommands') b=(!readonly)?true:false;
  if (props.itemMenuSub.text=='sharedComputedAttributes') b=(!features.disableComputedAttributes)&&(!readonly)?true:false;
  if (props.itemMenuSub.text=='sharedCalendars') b=(!features.disableCalendars)&&(!readonly)?true:false;
  if (props.itemMenuSub.text=='settingsServer') b=(admin)&&(manager)?true:false;
  if (props.itemMenuSub.text=='settingsUsers') b=(manager)?true:false;
  if (!b)
    return(<div/>);
  return(
  <MenuItem id={props.itemMenuSub.url} onClick={props.handleSettingsNotifications}>
          
  <Box
    sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, marginLeft: '15px', marginRight: '15px' }}
    style={{ float: 'left' }}
    id={props.itemMenuSub.url}
  >
  <div className="top-menu2" id={props.itemMenuSub.url}>
  {props.itemMenuSub.icon}
  <div className="top-menu-text2" id={props.itemMenuSub.url}>
    {
      t(props.itemMenuSub.text)
    }
  </div>

</div>
</Box>
  </MenuItem>  
  )
}

const HeaderMenu = (props) => {
  const t = useTranslation();

  return(
    <Box 
      sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' },'marginLeft': '15px','marginRight': '15px'  }}
      style={{ float: 'left' }}
    >
    <div className='top-menu'>
   
      {props.itemMenuTop.icon
      }
      <Button
        className={props.classes.icons}
        onClick={props.handleClickPark}
      >
        {props.itemMenuTop.text}
      </Button>
      <Menu id="mintoring-menu" anchorEl={props.anchorElPark} open={props.openPark} onClose={props.handleClosePark} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
        {props.itemMenuTop.subMenu.map((itemMenuSub) => (
          <HeaderSubMenu
            key={itemMenuSub.id}
            itemMenuSub={itemMenuSub}
            handleSettingsNotifications={props.handleSettingsNotifications}
          />                  
        ))}
      </Menu>
    </div>
  </Box>
  )
};

const HeaderContainer = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const t = useTranslation();


  // Park
  const [anchorElPark, setAnchorElPark] = React.useState(null);
  const openPark = Boolean(anchorElPark);
  const handleClickPark = (event) => {
    //console.log(event);
    setAnchorElPark(event.currentTarget);
  };
  const handleClosePark = () => {
    setAnchorElPark(null);
  };
  // Monitoring
  const [anchorElMonitoring, setAnchorElMonitoring] = React.useState(null);
  const openMonitoring = Boolean(anchorElMonitoring);
  const handleClickMonitoring = (event) => {
    setAnchorElMonitoring(event.currentTarget);
  };
  const handleCloseMonitoring = () => {
    setAnchorElMonitoring(null);
  };
  
  // Report
  const [anchorElReport, setAnchorElReport] = React.useState(null);
  const openReport = Boolean(anchorElReport);
  const handleClickReport = (event) => {
    setAnchorElReport(event.currentTarget);
  };
  const handleCloseReport = () => {
    setAnchorElReport(null);
  };
  // Setting
  const [anchorElSetting, setAnchorElSetting] = React.useState(null);
  const openSetting = Boolean(anchorElSetting);
  const handleClickSetting = (event) => {
    setAnchorElSetting(event.currentTarget);
  };
  const handleCloseSetting = () => {
    setAnchorElSetting(null);
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const user = useSelector((state) => state.session.user);
  const anchor = 'left';
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    //console.log(anchor);
    setState({ ...state, [anchor]: open });
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  // console.log(mapMenuTop);
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  //const desktop = useMediaQuery(theme.breakpoints.up('lg'));
  //console.log(desktop);

  const handleSettingsNotifications = (e) => {
    // setAnchorEl(null);
    // handleClose();
    e.preventDefault();

     //console.log(e.target);

    navigate(e.target.id);
  };
  const handleSettingsNavigateMap = () => {
    navigate('/');
  };

  // Нажата ссылка "Аккаунт"
  const handleAccount = () => {
    // setAnchorEl(null);
    navigate(`/settings/user/${user.id}`);
  };

  // Нажата ссылка "Выход"
  const handleLogout = async () => {
    // setAnchorEl(null);
    // setAnchorElUser(null);

    const notificationToken = window.localStorage.getItem('notificationToken');
    if (notificationToken) {
      window.localStorage.removeItem('notificationToken');
      const tokens = user.attributes.notificationTokens?.split(',') || [];
      if (tokens.includes(notificationToken)) {
        const updatedUser = {
          ...user,
          attributes: {
            ...user.attributes,
            notificationTokens: tokens.length > 1 ? tokens.filter((it) => it !== notificationToken).join(',') : undefined,
          },
        };
        await fetch(`/api/users/${user.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updatedUser),
        });
      }
    }

    await fetch('/api/session', { method: 'DELETE' });
    nativePostMessage('logout');
    navigate('/login');
    dispatch(sessionActions.updateUser(null));
  };

  return (

    <AppBar //position="static" 
    className="header-background">
      <div className='container'>
      <div className='left-column'>
      {desktop && (
              <LogoImage
                sx={{ display: { xs: 'none', md: 'flex' } }}
              />
            )}
            {!desktop && (
              <LogoImage sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, textAlign: 'center' }} />
            )}
        </div>
        <div className='middle-column'>

        <HeaderMenu 
          key={0}
          itemMenuTop={mapMenuTop[0]}
          classes={classes} 
          handleClosePark={handleClosePark} 
          anchorElPark={anchorElPark} 
          openPark={openPark} 
          handleSettingsNotifications={handleSettingsNotifications} 
          handleClickPark={handleClickPark}
        />                  

        <HeaderMenu 
          key={1}
          itemMenuTop={mapMenuTop[1]}
          classes={classes} 
          handleClosePark={handleCloseMonitoring} 
          anchorElPark={anchorElMonitoring} 
          openPark={openMonitoring} 
          handleSettingsNotifications={handleSettingsNotifications} 
          handleClickPark={handleClickMonitoring}
        />                  
        <HeaderMenu 
          key={2}
          itemMenuTop={mapMenuTop[2]}
          classes={classes} 
          handleClosePark={handleCloseReport} 
          anchorElPark={anchorElReport} 
          openPark={openReport} 
          handleSettingsNotifications={handleSettingsNotifications} 
          handleClickPark={handleClickReport}
        />                  
        <HeaderMenu 
          key={3}
          itemMenuTop={mapMenuTop[3]}
          classes={classes} 
          handleClosePark={handleCloseSetting} 
          anchorElPark={anchorElSetting} 
          openPark={openSetting} 
          handleSettingsNotifications={handleSettingsNotifications} 
          handleClickPark={handleClickSetting}
        />                  


        </div>
        <div className='right-column'>
        <Box
              sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }}}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>

                  </MenuItem>
                ))}

              </Menu>
            </Box>
            <Box
            
            // sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
              sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } ,'marginRight': '50px'
              //,'marginLeft': '15px','marginRight': '15px'  
            }}
              style={{ float: 'right' }}
            >

              <div className='top-menu' style={{textAlign: 'right'}}>
                <Button
                  //endIcon={<PersonIcon />}
                  onClick={handleOpenUserMenu}
                  className='top-menu-button'
                  sx={{ p: 0 }}
                >
                  Аккаунт
                </Button>
                <PersonIcon 
                  className='top-menu-icon' 
                  fontSize="large"
                />
              </div>

              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={handleAccount}>
                  <Typography color="textPrimary">{t('settingsUser')}</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Typography color="error">{t('loginLogout')}</Typography>
                </MenuItem>
              </Menu>
            </Box>
        </div>
      </div>
      
          </AppBar>
  );
};
export default HeaderContainer;

/*
              <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>

*/
/*

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <div key={page}>

              <Button
                key={page}
                //onClick={handleCloseNavMenu}
                onClick={handleClick}
                sx={{ my: 2, color: 'black', display: 'block' }}
              >
                {page}
              </Button>
              </div>

            ))}
          </Box>

*/
/*
              <Menu id="report-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{'aria-labelledby': 'basic-button',}}>
                  <MenuItem id="/settings/preferences" onClick={handleSettingsNotifications}>Аккаунт</MenuItem>
                  <MenuItem id="/settings/commands" onClick={handleSettingsNotifications}>Выход</MenuItem>
                  <MenuItem id="/settings/users" onClick={handleSettingsNotifications}> Пользователи </MenuItem>
                  <MenuItem id="/settings/attributes" onClick={handleSettingsNotifications}> Вычисляемые атрибуты </MenuItem>
                </Menu>

              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
                <Menu id="mintoring-menu" anchorEl={anchorElPark} open={openPark} onClose={handleClosePark} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                  <MenuItem id="/settings/devices" onClick={handleSettingsNotifications}>Транспорт</MenuItem>
                  <MenuItem id="/settings/drivers" onClick={handleSettingsNotifications}> Водители </MenuItem>
                  <MenuItem id="/settings/groups" onClick={handleSettingsNotifications}> Группы </MenuItem>
                </Menu>
                <Menu id="mintoring-menu" anchorEl={anchorElMonitoring} open={openMonitoring} onClose={handleCloseMonitoring} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                  <MenuItem id="/" onClick={handleSettingsNotifications}>Карта</MenuItem>
                  <MenuItem id="/reports/route" onClick={handleSettingsNotifications}> Маршруты </MenuItem>
                  <MenuItem id="/settings/notifications" onClick={handleSettingsNotifications}> Уведомления </MenuItem>
                </Menu>
      <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{'gridTemplateColumns': '300px 1fr 200px'}}
        >

        <Grid
          item
        >
        </Grid>

        </Grid>
        


*/
