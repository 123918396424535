import {
  amber, grey, green, indigo, red, common,blue,
} from '@mui/material/colors';
//: green[800]
//indigo[900],
const colors = {
  white: common.white,
  background: grey[50],
  primary: indigo[900],
  secondary: '#0F1B51',
  positive: green[500],
  medium: amber[700],
  negative: red[500],
  neutral: grey[500],
  geometry: '#3bb2d0',
  logo_color: '#44254E',
  logo_background: '#FCFCFC',
  iconsHarakiri:"#1D222A",
  iconsHarakiriHover: "#0D2F7C",
  iconsHarakiriPressed: "#164CC0",
  iconsColor:"#61605D",
};

export default {
  background: {
    default: colors.background,
  },
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
    contrastText: colors.white,
  },
  colors,
};
